<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Markisen / Wintergartenmarkise / Capri <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Capri - alles gut bedacht
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full lg:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full lg:col-span-1">
          <h3
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Markise für Glasdach, Wintergarten oder Terrasse
          </h3>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Als außenliegender Sonnenschutz für Wintergärten und Glasdächer
            schützt die Markise Capri vor übermäßigem Sonneneinfall und
            besonders bei Wintergärten vor Aufheizen des Innenraums. Erhältlich
            als Einzelfeld oder Koppelanlage ist diese Markise auch für große
            Flächen und Wintergärten geeignet. Als Typ Pergola kann die Markise
            auch ohne Glas-Unterbau vor einer Terrassenwand stehen.<br /><br />
          </p>
        </div>
      </div>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Typen">
            <div class="text-lg">
              <span class="font-bold"
                >Erhältlich in verschiedene Produktvarianten:</span
              >
              <br />
              <div class="flex flex-col sm:flex-row">
                <div class="px-2 flex flex-col w-2/3">
                  <div class="w-1/3">
                    <img
                      src="../assets/img/lewens/capri/lew-capri-d-typ-orange-xl.webp"
                      style="min-width: 10rem"
                      alt="wintergartenmarkise dachmontage"
                    />
                  </div>
                  <span class="lg:pl-16">Capri Typ Dachmontage</span>
                </div>
                <div class="px-2 flex flex-col w-2/3">
                  <div class="w-1/3">
                    <img
                      src="../assets/img/lewens/capri/lew-capri-p-typ-orange-xl.webp"
                      style="min-width: 10rem"
                      alt="wintergartenmarkise pergola"
                    />
                  </div>
                  <span class="lg:pl-16">Capri Typ Pergola</span>
                </div>
              </div>
            </div>
          </tab>
          <tab title="Maßen">
            <p class="text-lg">
              <span class="font-bold">Breite:</span> <br />
              max. 700 cm - 1-teilig (Einzelfeld)<br />
              <span class="font-bold">Ausfall/Tiefe:</span> <br />
              max. 600 cm <br />
              <span class="font-bold">Antrieb:</span> <br />
              Motorantrieb <br />
              <span class="font-bold">Markisentücher:</span> <br />
              Marken-Acryl <br />
              Marken-Polyester (Option)
            </p>
          </tab>
          <tab title="Zertifizierung">
            <div class="text-lg">
              <span class="font-bold">Zertifizierung:</span> <br />
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <div>
                    <img
                      src="../assets/madeingermany.webp"
                      alt="made in germany siegel"
                    />
                  </div>
                  <div class="w-full pl-1">
                    Hergestellt und montiert in Deutschland
                  </div>
                </div>
                <div class="flex flex-row">
                  <div>
                    <img src="../assets/tuev.webp" alt="tuev geprueft" />
                  </div>
                  <div class="w-full pl-1">TÜV-geprüfte Sicherheit</div>
                </div>
              </div>
            </div>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/lewens/capri/lew-capri-p-ganz-haus-uli.webp"),
          alt: "lewens capri wintergartenmarkise",
        },
        {
          image: require("@/assets/img/lewens/capri/lew-capri-d-ganz-haus1-uli.webp"),
          alt: " wintergartenmarkise 3d ausgefahren",
        },
        {
          image: require("@/assets/img/lewens/capri/lew-capri-d-ganz-haus2-uli.webp"),
          alt: "wintergartenmarkise dachmontage hausansicht",
        },
        {
          image: require("@/assets/img/lewens/capri/modelle-web-rd-11.webp"),
          alt: "lewens capri modell web rd 11",
        },
        {
          image: require("@/assets/img/lewens/capri/lew-capri-d-typ-orange-xl.webp"),
          alt: "capri lewens d typ",
        },
        {
          image: require("@/assets/img/lewens/capri/lew-capri-p-typ-orange-xl.webp"),
          alt: "capri lewens p typ",
        },
        {
          image: require("@/assets/img/lewens/capri/lew-capri-detail-technik-uli-1.webp"),
          alt: "capri lewens detail",
        },
        {
          image: require("@/assets/img/lewens/capri/lew-capri-p-detail-fs-xl.webp"),
          alt: "capri lewens p detail",
        },
      ],
    };
  },
};
</script>
